.section-search .card-map {
	background-color: white;
	padding: 0px 15px 15px 15px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 300px;
	height: 150px;
	overflow: hidden;
}

.section-search .card-error {
	background-color: white;
	padding: 0px 15px 15px 15px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	max-width: 300px;
	min-width: 300px;
}

.card-modal {
	padding-top: 15px;
	background-color: white;
	padding: 25px 25px 25px 25px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	max-width: 300px;
	width: 80vw;
	max-height: 500px;
	overflow: hidden;
	outline: none;
}

.card-modal .MuiIcon-root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: unset !important;
}

label {
	position: absolute;
	top: 6px;
	left: 80px;
	transition: all 0.1s ease-in-out;
	cursor: text;
	pointer-events: none;
	color: gray;
}

.user-label {
	position: absolute;
	top: 6px;
	left: 45px;
	transition: all 0.1s ease-in-out;
	cursor: text;
	pointer-events: none;
	color: gray;
}

.lab-address {
	position: absolute;
	top: 6px;
	left: 40px;
}

#bad-username::after {
	content: "non valide";
	color: red;
	font-size: 10px;
	margin-left: 15px;
	transition: ease-out all;
}

#bad-mail::after {
	content: "non valide";
	color: red;
	font-size: 10px;
	margin-left: 15px;
	transition: ease-out all;
}

#bad-password::after {
	content: "non valide";
	color: red;
	font-size: 10px;
	margin-left: 15px;
	transition: ease-out all;
}

#bad-passwordBis::after {
	content: "non valide";
	color: red;
	font-size: 10px;
	margin-left: 15px;
	transition: ease-out all;
}

.bad-input {
	border: red 1px solid !important;
}

:focus + label:not([data="checkbox"]),
.label-username,
.label-mail,
.label-password,
.label-passwordBis,
.label-address {
	color: rgb(236, 191, 155);
	top: -10px;
	background: white;
	font-size: 14px;
	transition: ease-out all 0.25s;
}

.warning {
	font-size: 10px;
	color: white;
	font-weight: bold;
}

.plate-result {
	display: flex;
	width: 250px;
	height: 40px;
	border: solid 2px black;
	border-radius: 5px;
	box-shadow: 15px 15px 20px #0000003b;
}

.plateNum-result {
	flex-grow: 3;
	text-align: center;
	background-color: white;
	font-weight: bold;
	font-size: 1.7rem;
}
