.section-addplate,
.section-stepInput,
.section-stepPics,
.section-stepGeoloc,
.section-stepDone,
.section-stepScan {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.module-scan {
	background: no-repeat url("../../assets/scan.png") center/100%;
}

.card {
	background-color: white;
	padding: 0px 30px 30px 30px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	max-height: 30vh;
	min-height: 30vh;
	max-width: 300px;
	min-width: 300px;
}

.module-input {
	text-align: center;
	max-height: none;
}

.card-mini {
	background-color: white;
	padding: 0px 15px 15px 15px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	max-height: 20vh;
	min-height: 100px;
	max-width: 300px;
	min-width: 300px;
}

.card-map {
	background-color: white;
	padding: 0px 15px 15px 15px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 300px;
	height: 150px;
	overflow: hidden;
}

.card-scan {
	background-color: white;
	padding: 0px 15px 15px 15px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	max-width: 300px;
	min-width: 300px;
}

.card-done {
	background-color: white;
	padding: 10px 10px 10px 10px;
	border-radius: 15px;
	box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	min-height: 50px;
	max-width: 300px;
	min-width: 300px;
}

.plate {
	display: flex;
	width: 280px;
	height: 50px;
	border: solid 2px black;
	border-radius: 5px;
	box-shadow: 15px 15px 20px #0000003b;
}

.leftPlate {
	background-color: blue;
	width: 40px;
}

.plateNum {
	flex-grow: 3;
	text-align: center;
	background-color: white;
	font-weight: bold;
	font-size: 2rem;
}

.rightPlate {
	background-color: blue;
	width: 40px;
}

.zone1,
.zone2,
.zone3 {
	font-size: 1.8rem;
	font-weight: bold;
	width: 60px;
	border: none;
	color: rgba(0, 0, 0, 0.84);
	text-shadow: 2px 2px 2px rgba(128, 128, 128, 0.664);
	text-align: center;
}

.plateValidationBtn {
	text-align: center;
}

.plate #outer-circle {
	margin-top: 100px !important;
}

.scan-cam video,
.scan-cam img {
	box-shadow: 0px 0px 20px rgb(255, 255, 255);
	height: 46px;
	object-fit: cover;
}

.add-cam video {
	border-radius: 10px;
	box-shadow: 0px 0px 20px rgb(255, 255, 255);
	height: 200px;
	object-fit: cover;
	width: 300px !important;
}

.add-cam .react-html5-camera-photo img {
	border-radius: 10px;
	height: 200px;
	width: 300px !important;
}

.add-cam #white-flash {
	border-radius: 10px;
	height: 200px;
}

.progress-bar {
	background-color: #fbfbfba4;
	border-radius: 20px;
	margin: 15px 0;
	height: 30px;
	width: 250px;
}

.progress-done {
	background: linear-gradient(to left, #f2709c, #ff9472);
	box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}

.back-top-btn {
	background: linear-gradient(to left, #f2709c, #ff9472);
	box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 50px;
}
