* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Nunito", sans-serif;
}

.App {
	height: 100vh;
	width: 100vw;
	background-image: linear-gradient(130deg, #ecc19e 25%, #eca64b 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.m-top15 {
	margin-top: 15%;
}

.m-top20 {
	margin-top: 20%;
}

input {
	outline: none;
}

textarea {
	padding: 10px;
	outline: none;
}
