
.header {
    width:  100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height : 100px;
    max-height : 200px;
    flex-grow: 1;
  }
  
  .logo {
    position: absolute;
    top: 0;
    align-content: center;
    width : 30%;
    max-width:300px;
    z-index:1;
  }
  
  .wave {
    min-height : 100px;
    max-height : 200px;
    position: relative;
    top :0;
    width : 100%;
  }

.login .MuiButton-label {
  margin-left: 9px;
}