.home{
    display:flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .insert-card {
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 2;
  }

  .search-card{
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
  }

  .main-card{
    background-color: white;
    padding : 10px 30px 30px 30px;
    border-radius: 15px;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.295);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
  }

   .small-card{
    max-height: 30vh;
    max-width: 80vw;
    min-width: 80vw;
  }

  .big-card{
    max-height: 30vh;
    min-height: 30vh;
    max-width: 500px;
    width: 80vw;
  }

  .insert-pic{
   width:60%;
  }

  .search-pic{
    width:60%;
   }
